import { createSlice } from '@reduxjs/toolkit';

// A product consists of > 0 items
export interface Product {
  id: number;
  name: string;
  price: number;
  items: Array<Item>;
  vat: number;
  bgColor: string;
}

export interface Item {
  id: number;
  name: string;
  price: number;
  vat: number;
  vatPercentage: number;
  amount: number;
}

export interface ProductGroup {
  id: number;
  name: string;
  products?: Array<Product>
}

interface ProductSliceState {
  isAmountSelectorOpen: boolean,
  productGroups: Array<ProductGroup>
  products: Array<Product>,
  searchResults: Array<Product>,
  searchProductGroupIndex: null | number;
  amountSelectorProductId: number | null
}

const initialState: ProductSliceState = {
  productGroups: [],
  products: [],
  searchResults: [],
  searchProductGroupIndex: null,
  isAmountSelectorOpen: false,
  amountSelectorProductId: null
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    addProduct(state, action: { payload: Product }) {
      state.products.push(action.payload);
    },
    addProductGroup(state, action: { payload: ProductGroup }) {
      state.productGroups.push(action.payload);

      if (action.payload.id === -1) {
        state.searchProductGroupIndex = state.productGroups.length - 1;
      }
    },
    addProductToGroup(state: ProductSliceState, action: { payload: { productGroupId: number, product: Product } }) {
      const { productGroupId, product } = action.payload;
      // Find the product group index in items
      const productGroupIndex = state.productGroups.findIndex((productGroup) => productGroup.id === productGroupId);

      if (productGroupIndex > -1) {
        // Push product to group
        state.productGroups[productGroupIndex].products.push(product);
      }
    },
    setSearchResults(state: ProductSliceState, action: { payload: Array<Product> }) {
      state.productGroups[state.searchProductGroupIndex].products = action.payload;
    },
    toggleAmountSelectorDialog(state: ProductSliceState, action: { payload: { open: boolean, productId?: number } }) {
      state.isAmountSelectorOpen = action.payload.open;
      state.amountSelectorProductId = action.payload.productId ? action.payload.productId : null;
    },
    clearProducts() {
      return initialState;
    }
  }
});

export const {
  addProduct,
  addProductToGroup,
  setSearchResults,
  toggleAmountSelectorDialog,
  addProductGroup,
  clearProducts
} = productSlice.actions;

export default productSlice.reducer;