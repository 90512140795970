import { Payment } from '../../types/types';
import pRetry from 'p-retry';
import { GetGiftCardBalanceResponse } from '../../types/api';

const sendPaymentDataWithRetry = async (data: Payment): Promise<string> => {
 const response = await fetch(process.env.NEXT_PUBLIC_API + '/newEvent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });

  if (response.status === 404) {
    throw new pRetry.AbortError(response.statusText);
  }

  return response.text();
};

export const sendPaymentData = async (data: Payment): Promise<string> => {
  return await pRetry(() => sendPaymentDataWithRetry(data), { retries: 3 });
}

/**
 * Get balance left on a gift card
 *
 * @param giftCardNumber
 */
export const getGiftCardBalance = async (giftCardNumber: string): Promise<GetGiftCardBalanceResponse> => {
    const response = await fetch(process.env.NEXT_PUBLIC_API_URL + `/getGiftCard/${giftCardNumber}`);
    return response.json();
};