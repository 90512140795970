import { createSlice } from '@reduxjs/toolkit';

type TerminalStatusIndex = 'ready_for_transaction' | 'psp_connection_available' | 'transaction_status';

export interface TerminalStatus {
  ready_for_transaction: boolean;
  psp_connection_available: boolean;
  transaction_status: string;
}

export interface DeviceState {
  logEntries: string[],
  status: { [K in TerminalStatusIndex]: unknown };
}

export interface TerminalSliceState {
  dialogOpen: boolean;
  devices: { [key:string]: DeviceState }
}

const initialState: TerminalSliceState = {
  dialogOpen: false,
  devices: {}
};

const terminalSlice = createSlice({
  name: 'terminal',
  initialState,
  reducers: {
    reset(state: TerminalSliceState) {
      Object.keys(state.devices).forEach((terminalId) => {
        state.devices[terminalId].status = {
          ready_for_transaction: false,
          psp_connection_available: false,
          transaction_status: false,
        };
      });
    },
    toggleEditTerminalDialog(state: TerminalSliceState, action: { payload: boolean }) {
      state.dialogOpen = action.payload;
    },
    addDevice(state: TerminalSliceState, action: { payload: string }) {
        state.devices[action.payload] = {
          logEntries: [],
          status: {
            ready_for_transaction: false,
            psp_connection_available: false,
            transaction_status: false,
          }
        };
    },
    setLogEntry(state: TerminalSliceState, action: { payload: { entry: string, terminalId: string } }) {
      const { entry, terminalId } = action.payload;

      if (state.devices[terminalId]) {
        state.devices[terminalId].logEntries.push(entry);
      }
    },
    updateStatus(state: TerminalSliceState, action: { payload: { status: TerminalStatus, terminalId: string } }) {
      const { status, terminalId } = action.payload;
      const message = status;

      const statusKeys: Array<TerminalStatusIndex> = [
        'ready_for_transaction',
        'psp_connection_available'
      ];

      statusKeys.forEach((k: TerminalStatusIndex) => state.devices[terminalId].status[k] = typeof message[k] === 'boolean' && message[k]);
      state.devices[terminalId].status.transaction_status = message.transaction_status;
    }
  }
});

export const {
  reset,
  addDevice,
  toggleEditTerminalDialog,
  setLogEntry,
  updateStatus
} = terminalSlice.actions;

export default terminalSlice.reducer;