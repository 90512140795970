import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { sendPaymentData as sendPaymentDataApiCall } from '../../assets/js/api';
import { Payment } from '../../types/types';
import { RootState } from '../store';

const MAX_STORED_PURCHASES = 20;

export enum PurchaseStates {
  OPEN = 'open',
  COMPLETED = 'completed',
  LOADING = 'loading',
  PENDING = 'pending',
  CANCELLED = 'cancelled'
}

export enum PurchaseDeliveryStatus {
  NOT_DELIVERED = 'not_delivered',
  DELIVERED = 'delivered',
}

export interface PurchaseProductRow {
  productId: number;
  count: number;
}

export interface Purchase {
  id: string;
  sequenceId: string;
  title: string;
  giftCardCode?: string;
  discount?: number;
  discountPercentage?: number;
  status: PurchaseStates;
  deliveryStatus: PurchaseDeliveryStatus;
  updated: string;
  rows: Array<PurchaseProductRow>;
  paymentData: null | Payment;
  paymentDeviceIndex?: string;
  paymentPendingOnTerminalIndex: null | string;
  comment?: string;
}

export interface PurchaseSliceState {
  purchases: Array<Purchase>,
  selectedPurchase: null | string;
  isRowEditorOpen: boolean;
  editedPurchaseRow: null | number;
  isReceiptDialogOpen: boolean;
}

const initialState: PurchaseSliceState = {
  purchases: [],
  selectedPurchase: null,
  isRowEditorOpen: false,
  editedPurchaseRow: null,
  isReceiptDialogOpen: false
};

const purchasesSlice = createSlice({
    name: 'purchases',
    initialState,
    reducers: {
      // Diplay purchase row editor dialog
      toggleRowEditor(state: PurchaseSliceState, action: { payload: boolean }) {
        state.isRowEditorOpen = action.payload;
      },
      // Display receipt dialog
      toggleReceiptDialog(state: PurchaseSliceState, action: { payload: boolean }) {
        state.isReceiptDialogOpen = action.payload;
      },
      // Delete product row from purchase
      deletePurchaseRow(state: PurchaseSliceState, action: { payload: number }) {
        // Find the purchase
        const purchaseIndex = state.purchases.findIndex(purchase => {
          return purchase.id === state.selectedPurchase;
        });

        if (purchaseIndex > -1) {
          const purchaseRows = state.purchases[purchaseIndex].rows;
          // Update timestamp
          state.purchases[purchaseIndex].updated = new Date().toISOString();
          // Filter out the row in question
          state.purchases[purchaseIndex].rows = purchaseRows.filter((row, index) => index !== action.payload);
          // Hide editor dialog
          state.isRowEditorOpen = false;
          // Set purchase row index info back to nul
          state.editedPurchaseRow = null;
        }
      },
      // Update purchase product row count
      updateRowCount(state: PurchaseSliceState, action: { payload: number }) {
        // This reducer assumes the purchase and row being edited are the ones on the current state
        // Find purchase
        const purchaseIndex = state.purchases.findIndex(purchase => {
          return purchase.id === state.selectedPurchase;
        });

        if (purchaseIndex > -1) {
          const existing = state.purchases[purchaseIndex].rows.findIndex((p, index) => index === state.editedPurchaseRow);

          if (existing > -1) {
            state.purchases[purchaseIndex].updated = new Date().toISOString();
            // Update row count
            state.purchases[purchaseIndex].rows[existing].count = action.payload;
            // Clear row edit index
            state.editedPurchaseRow = null;
            // Hide dialog
            state.isRowEditorOpen = false;
          }
        }
      },
      // Store the purchase row before it's edited in a dialog
      setEditedPurchaseRow(state: PurchaseSliceState, action: { payload: number }) {
        state.editedPurchaseRow = action.payload;
      },
      addDiscount(state: PurchaseSliceState, action: { payload: { giftCardCode: string, discount: number } }) {
        const purchaseIndex = state.purchases.findIndex(purchase => {
          return purchase.id === state.selectedPurchase;
        });

        if (purchaseIndex > -1) {
          state.purchases[purchaseIndex].giftCardCode = action.payload.giftCardCode;
          state.purchases[purchaseIndex].discount = action.payload.discount;
        }
      },
      addDiscountPercentage(state: PurchaseSliceState, action: { payload: number }) {
        const purchaseIndex = state.purchases.findIndex(purchase => {
          return purchase.id === state.selectedPurchase;
        });

        if (purchaseIndex > -1) {
          state.purchases[purchaseIndex].discountPercentage = action.payload;
        }
      },
      removeDiscountPercentage(state: PurchaseSliceState) {
        const purchaseIndex = state.purchases.findIndex(purchase => {
          return purchase.id === state.selectedPurchase;
        });

        if (purchaseIndex > -1) {
          state.purchases[purchaseIndex].discountPercentage = undefined;
        }
      },
      removeDiscount(state: PurchaseSliceState) {
        const purchaseIndex = state.purchases.findIndex(purchase => {
          return purchase.id === state.selectedPurchase;
        });

        if (purchaseIndex > -1) {
          state.purchases[purchaseIndex].giftCardCode = undefined;
          state.purchases[purchaseIndex].discount = undefined;
        }
      },
      // Add products to the selected, open, purchase
      addProductToOpenPurchase(state: PurchaseSliceState, action: { payload: { id: number, count?: number } }) {
        const purchaseIndex = state.purchases.findIndex(purchase => {
          return purchase.id === state.selectedPurchase;
        });

        if (purchaseIndex > -1) {
          // Update timestamp
          state.purchases[purchaseIndex].updated = new Date().toISOString();

          const existing = state.purchases[purchaseIndex].rows.findIndex(product => product.productId === action.payload.id);

          if (existing > -1) {
            // Add to existing row
            state.purchases[purchaseIndex].rows[existing].count = state.purchases[purchaseIndex].rows[existing].count + (action.payload.count ? action.payload.count : 1);
          } else {
            // Add new row
            state.purchases[purchaseIndex].rows.push({
              productId: action.payload.id,
              count: action.payload.count ? action.payload.count : 1
            });
          }
        }
      },
      // Store the selected (being edited) purchase
      setSelectedPurchase(state: PurchaseSliceState, action) {
        state.selectedPurchase = action.payload;
      },
      // Create a new, empty, purchase
      startPurchase(state) {
        const id: string = uuidv4();

        state.purchases.push({
          id,
          sequenceId: format(new Date(), 'T'),
          title: format(new Date(), 'kk:mm:ss'),
          status: PurchaseStates.OPEN,
          rows: [],
          deliveryStatus: PurchaseDeliveryStatus.NOT_DELIVERED,
          updated: new Date().toISOString(),
          paymentData: null,
          paymentPendingOnTerminalIndex: null
        });

        state.selectedPurchase = id;
      },
      clearExtraPurchases(state: PurchaseSliceState) {
        // Clear any purchase from state after MAX_STORED_PURCHASE, which is marked as completed
        if (state.purchases.length >= MAX_STORED_PURCHASES) {
          for (let i = 0; i < state.purchases.length - MAX_STORED_PURCHASES; i++) {
            const purchase = state.purchases[i];

            if (purchase.status === PurchaseStates.COMPLETED) {
              state.purchases.splice(i, 1);
            }
          }
        }
      },
      // Mark purchase in a 'pending' state (usually when payment process starts)
      moveLoadingToPending(state: PurchaseSliceState) {
        // Find purchase from open
        const purchaseIndex = state.purchases.findIndex(purchase => purchase.status === PurchaseStates.LOADING);

        if (purchaseIndex > -1) {
          // Move to completed
          state.purchases[purchaseIndex].status = PurchaseStates.PENDING;
          // Update timestamp
          state.purchases[purchaseIndex].updated = new Date().toISOString();
        }
      },
      // Mark purchase in a 'loading' state. This is a state where we wait for the payment device to answer the purchase event.
      moveToLoading(state: PurchaseSliceState, action: { payload: { purchaseId: string, terminalId: string } }) {
        const { purchaseId, terminalId } = action.payload;

        // Find purchase from open
        const purchaseIndex = state.purchases.findIndex(purchase => purchase.id === purchaseId);

        if (purchaseIndex > -1) {
          // Move to completed
          state.purchases[purchaseIndex].status = PurchaseStates.LOADING;
          // Update timestamp
          state.purchases[purchaseIndex].updated = new Date().toISOString();
          // Set the device where the payment is pending at
          state.purchases[purchaseIndex].paymentDeviceIndex = terminalId;
          state.purchases[purchaseIndex].paymentPendingOnTerminalIndex = terminalId;
        }
      },
      cancelAllPendingPurchasesOnTerminalId(state: PurchaseSliceState, action: { payload: string }) {
        // Find all purchases which have payment pending on this terminal device
        const purchases = state.purchases.filter((purchase) => purchase.paymentPendingOnTerminalIndex === action.payload);

        if (purchases.length > 0) {
          purchases.map(purchase => {
            // Find the purchase index so we can update the correct one
            const purchaseIndex = state.purchases.findIndex((p) => p.id === purchase.id);

            if (purchaseIndex > -1) {
              // Move to completed
              state.purchases[purchaseIndex].status = PurchaseStates.OPEN;
              // Update timestamp
              state.purchases[purchaseIndex].updated = new Date().toISOString();
              // Reset pending device
              state.purchases[purchaseIndex].paymentPendingOnTerminalIndex = null;
            }
          });
        }
      },
      updateComment(state: PurchaseSliceState, action: { payload: { purchaseId: string, comment: string } }) {
        const { purchaseId, comment } = action.payload;

        const purchaseIndex = state.purchases.findIndex(purchase => purchase.id === purchaseId);

        if (purchaseIndex > -1) {
          state.purchases[purchaseIndex].comment = comment;
        }
      },
      cancelPending(state: PurchaseSliceState, action: { payload: string }) {
        // Find purchase from open
        const purchaseIndex = state.purchases.findIndex(purchase => purchase.id === action.payload);

        if (purchaseIndex > -1) {
          // Move to completed
          state.purchases[purchaseIndex].status = PurchaseStates.OPEN;
          // Update timestamp
          state.purchases[purchaseIndex].updated = new Date().toISOString();
          // Reset pending device
          state.purchases[purchaseIndex].paymentPendingOnTerminalIndex = null;
        }
      },
      deletePurchase(state: PurchaseSliceState, action: { payload: string }) {
        const purchaseIndex = state.purchases.findIndex(purchase => purchase.id === action.payload);

        if (purchaseIndex > -1) {
          state.purchases.splice(purchaseIndex, 1);

          if (state.purchases.length > 0) {
            state.selectedPurchase = state.purchases[0].id;
          }
        }
      },
      // Mark purchase as completed
      completePurchase(state: PurchaseSliceState, action: { payload: { purchaseId: string, paymentData: Payment } }) {
        const { purchaseId, paymentData } = action.payload;
        let payment = paymentData;

        // Find purchase from open
        const purchaseIndex = state.purchases.findIndex(purchase => purchase.id === purchaseId);

        if (purchaseIndex > -1) {
          const productRows = state.purchases[purchaseIndex].rows;
          // Move to completed
          state.purchases[purchaseIndex].status = PurchaseStates.COMPLETED;
          // Update timestamp
          state.purchases[purchaseIndex].updated = new Date().toISOString();

          // Add gift card info from purchase to payment data, if one exists
          if (state.purchases[purchaseIndex].giftCardCode) {
            payment = {
              ...paymentData,
              giftcard: {
                code: state.purchases[purchaseIndex].giftCardCode,
                amount: state.purchases[purchaseIndex].discount
              }
            };
          }

          state.purchases[purchaseIndex].paymentData = { ...payment, product_rows: productRows };
        }
      },
      markPurchaseAsDelivered(state: PurchaseSliceState, action: { payload: string }) {
        const purchaseIndex = state.purchases.findIndex(purchase => purchase.id === action.payload);

        if (purchaseIndex > -1) {
          state.purchases[purchaseIndex].deliveryStatus = PurchaseDeliveryStatus.DELIVERED;
        }
      }
    },
  }
);

export const {
  startPurchase,
  completePurchase,
  setSelectedPurchase,
  addProductToOpenPurchase,
  toggleRowEditor,
  moveLoadingToPending,
  moveToLoading,
  setEditedPurchaseRow,
  deletePurchaseRow,
  updateRowCount,
  cancelPending,
  toggleReceiptDialog,
  updateComment,
  addDiscount,
  removeDiscount,
  cancelAllPendingPurchasesOnTerminalId,
  clearExtraPurchases,
  deletePurchase,
  addDiscountPercentage,
  removeDiscountPercentage,
} = purchasesSlice.actions;

/**
 * Send all non-delivered payment data to the API for permanent storage
 */
export const sendPaymentData = createAsyncThunk('purchases/sendPaymentData', async (result, {
  dispatch,
  getState
}) => {
  const { purchases } = getState() as RootState;

  if (purchases.purchases.length > 0) {
    for (const purchase of purchases.purchases) {
      // Only send non delivered purchases with existing paymentData
      if (purchase.paymentData !== null && purchase.deliveryStatus === PurchaseDeliveryStatus.NOT_DELIVERED) {
        try {
          const response = await sendPaymentDataApiCall(purchase.paymentData);

          if (response.toString() === '1') {
            // Store delivery status
            await dispatch(purchasesSlice.actions.markPurchaseAsDelivered(purchase.id));
          }
        } catch (err) {
          // Marking payment failed
        }
      }
    }
  }
});

export default purchasesSlice.reducer;