import { createSlice } from '@reduxjs/toolkit';

export interface AppState {
  usedTerminalConnections: string[];
  selectedCategoryIndex: number;
  cashRegistryId: string | undefined;
  giftCardDialogOpen: boolean;
  discountDialogOpen: boolean;
}

const initialState: AppState = {
  usedTerminalConnections: [],
  selectedCategoryIndex: 0,
  cashRegistryId: undefined,
  giftCardDialogOpen: false,
  discountDialogOpen: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSelectedCategory: (state: AppState, action: { payload: number }) => {
      state.selectedCategoryIndex = action.payload;
    },
    setCashRegistryId: (state: AppState, action: { payload: string }) => {
      state.cashRegistryId = action.payload;
    },
    toggleGiftCardDialog: (state: AppState, action: { payload: boolean }) => {
      state.giftCardDialogOpen = action.payload;
    },
    toggleDiscountDialog: (state: AppState, action: { payload: boolean }) => {
      state.discountDialogOpen = action.payload;
    },
    setUsedTerminalConnection: (state: AppState, action: { payload: string }) => {
      state.usedTerminalConnections.push(action.payload);
    },
    removeUsedTerminalConnection: (state: AppState, action: { payload: string }) => {
      state.usedTerminalConnections = state.usedTerminalConnections.filter((index) => index !== action.payload);
    },
    resetTerminalConnections: (state: AppState, action: { payload: string }) => {
      state.usedTerminalConnections = [];
    },
  }
});

export const {
  setSelectedCategory,
  setCashRegistryId,
  toggleGiftCardDialog,
  setUsedTerminalConnection,
  removeUsedTerminalConnection,
  resetTerminalConnections,
  toggleDiscountDialog
} = appSlice.actions;

export default appSlice.reducer;