import { CombinedState, combineReducers, Reducer } from 'redux';

import products from './app/productSlice';
import purchases from './app/purchasesSlice';
import terminal from './app/terminalSlice';
import app from './app/appSlice';

const reducers: Reducer<CombinedState<any>> = combineReducers({
  products,
  purchases,
  terminal,
  app
});

export default reducers;